import {AvailabilityTabs} from './../availability.view.component/availability.view.component';
import {AlarmTabs} from './../alarm.view.component/alarm.view.component';
import {WacheTabs} from './../wache.view.component/wache.view.component';
import {IHttpService, ILocationService, ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import {EFE2Features, RevisionState, UserAccount} from "../../../data/account.data";
import {EServerMode, WebSocketServerModeEvent} from "../../../data/socket.data";
import RestService from "../../../services/rest.service";
import PrivilegeService from '../../../services/privilege.service';
import {RolePrivilege} from '../../../data/privileges.enum';
import HelperService from '../../../services/helper.service';
import AccountService from '../../../services/account.service';

'use strict';
import angular = require("angular");
import {KbaRequestEvent} from '../../../data/admin.data';

require('./main.view.component.scss');

/* @ngInject */
export default class MainController {
  public $log: ILogService;
  public $rootScope: IRootScopeService;
  public $scope: IScope;
  public $state: any;
  public $uibModal: any;
  public dataService: any;
  public restService: RestService;
  public socketService: any;
  public listeners = [];
  public failedRules: number = 0;
  public disabledUnits: number = 0;
  public isReleaseVersion: boolean = true;
  public isSlaveMode: boolean = false;
  public alerts = [];
  public wsState: WsState;
  public account: UserAccount;
  public hideHeaderImage = false;
  public hideSidebar = false;
  public loggedIn = false;
  public headerImage: string;
  public newAlert = false;
  public userSwitchToggle = false;
  public queueFeatureEnabled = false;

  public addressbookToggle: boolean;
  public vehiclesToggle: boolean;
  public unitsToggle: boolean;
  public alarmToggle: boolean;
  public objectsToggle: boolean;
  public availabilityToggle: boolean;
  public calendarToggle: boolean;
  public statisticToggle: boolean;
  public announcementToggle: boolean;
  public adminToggle: boolean;
  public revision: RevisionState = {
    fe2Revision: false,
    pluginRevision: false
  };
  public overviewToggle: boolean;
  public leaderboardToggle: boolean;
  public overviewTabIndex = 0;

  public currentLanguage = 'de';

  private $window: IWindowService;
  private $http: IHttpService;

  public logo: string;

  constructor($rootScope: IRootScopeService, private $translate: any, $scope: IScope, $state, $log: ILogService,
              private $location: ILocationService, $uibModal, dataService, restService: RestService, socketService,
              public privilegeService: PrivilegeService, private helperService: HelperService, private accountService: AccountService,
              $http: IHttpService, $window: IWindowService) {
    $log.debug('MainCtrl started...');
    this.$log = $log;
    this.$rootScope = $rootScope;
    this.$scope = $scope;
    this.$state = $state;
    this.$uibModal = $uibModal;
    this.dataService = dataService;
    this.restService = restService;
    this.socketService = socketService;
    this.$http = $http;
    this.$window = $window;
    this.wsState = {
      isConnected: false
    } as WsState;

    this.initListeners();
    this.init();

    // Unregister
    this.$scope.$on('$destroy', () => {
      //Each listener has a unregister function. They are stored in listeners array
      this.listeners.forEach((listener) => {
        listener();
      });
    });

    const hideHeader = $location.search()['hideHeader'];
    if (hideHeader) {
      // Header image should be hidden
      this.hideHeaderImage = hideHeader === 'true';
    }

    const ua = navigator.userAgent;

    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
      this.hideSidebar = true;
    }
  }

  loggedInFromAdmin(): boolean {
    if (angular.isUndefined(this.account) || !this.account) {
      return false;
    }
    if (!this.loggedIn) {
      return false;
    }
    return this.account.loggedInFromAdmin;
  }

  getSwitchBackUserName(): string {
    if (angular.isUndefined(this.account) || !this.account) {
      return "";
    }
    if (!this.loggedIn) {
      return "";
    }
    return this.account.sourceUsername;
  }

  switchBackToAdmin() {
    if (!this.account) {
      return;
    }
    if (this.loggedIn) {
      this.dataService.switchBackToAdmin(this.account, success => {
        this.$log.debug('Switched back to administration: ' + success);
        this.toggleAdmin();
      }, error => {
        this.$log.error(error);
      });
    }
  }

  loadLogo() {
    this.restService.getLogo().then(logo => this.logo = logo);
  }

  /**
   * Load the current nbr of deactivated units
   */
  loadDeactivatedUnits() {
    this.restService.loadDeactivatedUnits().then(result => {
      this.disabledUnits = result.deactivatedUnits;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  /**
   * Load the number of failed rules
   */
  loadNumberOfFailedRules() {
    this.restService.loadFailedAvailabilityRules().then(result => {
      this.failedRules = result.failedRules;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  /**
   * Init event listeners
   */
  initListeners() {

    this.listeners.push(this.$rootScope.$on('main.logout', () => {
      this.$log.debug('MainController: main.logout triggered!');
      this.logout();
    }));

    //Listen to events
    this.listeners.push(this.$rootScope.$on('rules.updated', (event, data) => {
      this.loadNumberOfFailedRules();
    }));

    this.listeners.push(this.$rootScope.$on('units.updated', () => {
      this.loadDeactivatedUnits();
    }));

    this.listeners.push(this.$rootScope.$on('$stateChangeSuccess',
      (event, toState, toParams, fromState, fromParams) => {
        this.$log.debug('Entering state: ' + toState.name);
        if (toState.name === 'main.home') {
          //Check if is initalized
          this.init();
        }
      }));

    // Websockets
    this.listeners.push(this.$rootScope.$on('websocket.connection',
      (event, data) => {
        this.$log.debug('Websocket State Change: ' + data.isConnected);
        if (!this.wsState.isConnected && data.isConnected) {
          //reload revision status after reconnect of websocket (FE2 might have been restarted)
          this.$rootScope.$emit('pluginRevision.change');
        }
        this.wsState = data;
        this.$scope.$applyAsync();
      }));

    // New incoming alert
    this.listeners.push(this.$rootScope.$on('new.alarm', () => {
      this.newAlert = true;
      this.$scope.$applyAsync();
    }));

    // Change to overview
    this.listeners.push(this.$rootScope.$on('clear.alarm', () => {
      this.newAlert = false;
      this.$scope.$applyAsync();
    }));

    // Incoming KBA Request
    this.listeners.push(this.$rootScope.$on('new.kba.request', (event, data: KbaRequestEvent) => {
      // Open modal
      this.openKbaRequestApprovalModal();
    }));

    // Server Mode Change
    this.listeners.push(this.$rootScope.$on('server.mode', (event, data: WebSocketServerModeEvent) => {
      if (this.account) {
        this.isSlaveMode = data.mode === EServerMode.SLAVE;
        this.$scope.$applyAsync();
      }
    }));

    //New logo event
    this.listeners.push(this.$rootScope.$on('logo.updated', () => {
      this.loadLogo();
    }));

    //Wait for new account
    this.listeners.push(this.$rootScope.$on('new.account', (event, account) => {
      //Init controller
      this.account = account;
      this.loggedIn = true;
      if (account.version.installed.type === 'STABLE' || account.version.installed.type === 'RC') {
        this.isReleaseVersion = true;
      } else {
        this.isReleaseVersion = false;
      }

      if (this.account.server) {
        this.isSlaveMode = this.account.version.mode === EServerMode.SLAVE;

        if (this.account.server.pendingKbaApprovals) {
          this.openKbaRequestApprovalModal();
        }
      }

      this.queueFeatureEnabled = this.accountService.isFeatureEnabled(EFE2Features.QUEUES);

      this.loadLogo();
      this.startSocket();

      this.restService.isRevisionActive().then(revisionState => {
        this.revision = revisionState;
      });

      if (this.privilegeService.has(RolePrivilege.Units)) {
        this.loadDeactivatedUnits();
      }

      if (this.privilegeService.has(RolePrivilege.Availability_Rules)) {
        this.loadNumberOfFailedRules();
      }
    }));

    this.listeners.push(this.$rootScope.$on('tab.select.alarm', () => {
      this.toggleAlarm();
    }));

    this.listeners.push(this.$rootScope.$on('tab.select.home', () => {
      this.toggleHome();
    }));

    this.listeners.push(this.$rootScope.$on('pluginRevision.change', () => {
      this.restService.isRevisionActive().then(revisionState => {
        this.revision = revisionState;
        this.$scope.$applyAsync();
      });
    }));


    //Wait for LOGOUT
    this.listeners.push(this.$rootScope.$on('delete.account', () => {
      this.account = undefined;
    }));
  };

  /**
   * Open the KBA approval modal
   */
  openKbaRequestApprovalModal() {
    if (this.helperService.kbaApproveRequestModalOpen) {
      // Modal already open
      return;
    }
    this.$uibModal.open({
      template: require('../../modals/admin/approve.kba.request.modal/approve.kba.request.modal.html'),
      controller: 'ModalApproveKbaRequestController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg'
    });
  }


  doDisplayWarnings(): boolean {
    // then licence is invalid only this one is relevant
    return !this.account.licenceInvalid;
  }

  downloadOldShareCsv() {
    this.$window.open(
      this.restService.getBaseUrl() +
      '/admin/oldShares/download?Authorization=' +
      this.$http.defaults.headers.common.Authorization,
      '_blank'
    );
  }

  /**
   * Start the websocket connection
   */
  startSocket() {
    // Start websocket connection
    this.socketService.reconnect();
  }

  init() {
    this.currentLanguage = this.helperService.getFromStorage('language', 'de');
    this.dataService.isLoggedIn((account) => {
      this.account = account;
      this.queueFeatureEnabled = this.accountService.isFeatureEnabled(EFE2Features.QUEUES);
      if (account.version.installed.type === 'STABLE' || account.version.installed.type === 'RC') {
        this.isReleaseVersion = true;
      } else {
        this.isReleaseVersion = false;
      }

      this.loadLogo();

    }, () => {
      //No account loaded
      this.$state.go('main.login');
    });

    this.headerImage = this.getHeader();
  };

  /**
   * Return if user has privilege or not
   */
  hasPrivilege(privilege: RolePrivilege) {
    return this.privilegeService.has(privilege)
  };

  hasFeature(feature: EFE2Features): boolean {
    if (this.account) {
      return this.account.features.includes(feature);
    }
    return false;
  }


  /**
   * Switch the current language
   */
  changeLanguage() {
    if (this.currentLanguage === 'de') {
      // Switch to EN
      this.$translate.use('en');
      this.currentLanguage = 'en';
    } else {
      // Switch to DE
      this.$translate.use('de');
      this.currentLanguage = 'de';
    }
    this.helperService.saveInStorage('language', this.currentLanguage);
  }


  /**
   * Logout from UI
   */
  logout() {
    this.loggedIn = false;
    this.resetToggles();
    this.dataService.logout();
    setTimeout(() => {
      this.$state.go('main.login');
      //Switch scope to login
    }, 300)
  };

  closeAlert(index: number) {
    this.alerts.splice(index, 1);
  };

  /**
   * Get the header image
   */
  getHeader() {
    return this.restService.getStaticImageUrl('header.jpg');
  }

  /**
   Opens email to admin popup
   */
  openSettings() {
    this.$uibModal.open({
      template: require('../../modals/misc/settings.modal/settings.modal.html'),
      controller: 'SettingsInstanceModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg',
      resolve: {
        settingsTab: () => {
          return undefined;
        }
      }
    });
  };

  openSystemStatus() {
    this.$uibModal.open({
      template: require('../../modals/misc/system.status.modal/system.status.modal.html'),
      controller: 'SystemStatusModalController',
      controllerAs: 'ctrl',
      backdrop: 'static',
      size: 'lg'
    });
  };


  toggleSidebar(hide?: boolean) {
    if (hide !== undefined) {
      this.hideSidebar = hide;
    } else {
      this.hideSidebar = !this.hideSidebar;
    }

    this.$rootScope.$emit('menu.hidden', this.hideSidebar);
  }

  subMenuWacheClicked(tab: WacheTabs) {
    if (!this.vehiclesToggle && tab === WacheTabs.ANNOUNCEMENTS) {
      this.vehiclesToggle = true;
    }
    this.$state.go('main.wache', {
      tab: tab
    }).then(() => {
      this.$rootScope.$emit('tab.change.wache', tab);
    });
  }

  subMenuUnitsClicked(tabIndex: number) {
    this.$location.search('tab', tabIndex);
    this.$rootScope.$emit('tab.change.unit', tabIndex);
  }

  subMenuAddressbookClicked(tabIndex: number) {
    this.$location.search('tab', tabIndex);
    this.$rootScope.$emit('tab.change.addressbook', tabIndex);
  }

  subMenuAlarmClicked(tab: AlarmTabs) {
    this.$location.search('tab', tab);
    this.$rootScope.$emit('tab.change.alarm', tab);
  }

  subMenuObjectClicked(tabIndex: number) {
    this.$location.search('tab', tabIndex);
    this.$rootScope.$emit('tab.change.object', tabIndex);
  }

  subMenuAvailabilityClicked(tab: AvailabilityTabs) {
    this.$location.search('tab', tab);
    this.$rootScope.$emit('tab.change.availability', tab);
  }

  subMenuCalendarClicked(tabIndex: number) {
    this.$location.search('tab', tabIndex);
    this.$rootScope.$emit('tab.change.calendar', tabIndex);
  }

  subMenuAdminClicked(tabIndex?: number) {
    this.$state.go('main.admin', {
      tab: tabIndex
    }).then(() => {
      this.$rootScope.$emit('tab.change.admin', tabIndex);
    });
  }

  subMenuOverviewClicked(tabIndex: number) {
    this.overviewTabIndex = tabIndex;
  }

  resetToggles() {
    this.addressbookToggle = false;
    this.vehiclesToggle = false;
    this.unitsToggle = false;
    this.alarmToggle = false;
    this.objectsToggle = false;
    this.availabilityToggle = false;
    this.calendarToggle = false;
    this.statisticToggle = false;
    this.adminToggle = false;
    this.userSwitchToggle = false;
    this.overviewToggle = false;
    this.leaderboardToggle = false;
    this.announcementToggle = false;
  }

  toggleHome() {
    let newValue = !this.overviewToggle;
    this.resetToggles();
    this.overviewToggle = newValue;

  }

  toggleAddressbook() {
    let newValue = !this.addressbookToggle;
    this.resetToggles();
    this.addressbookToggle = newValue;
  }

  toggleVehicles() {
    let newValue = !this.vehiclesToggle;
    this.resetToggles();
    this.vehiclesToggle = newValue;
    this.subMenuWacheClicked(WacheTabs.VEHICLES);
  }

  toggleUnits() {
    let newValue = !this.unitsToggle;
    this.resetToggles();
    this.unitsToggle = newValue;
  }

  toggleAlarm() {
    let newValue = !this.alarmToggle;
    this.resetToggles();
    this.alarmToggle = newValue;
  }

  toggleObjects() {
    let newValue = !this.objectsToggle;
    this.resetToggles();
    this.objectsToggle = newValue;
  }

  toggleAvailability() {
    let newValue = !this.availabilityToggle;
    this.resetToggles();
    this.availabilityToggle = newValue;
  }

  toggleCalendar() {
    let newValue = !this.calendarToggle;
    this.resetToggles();
    this.calendarToggle = newValue;
  }

  toggleStatistic() {
    let newValue = !this.statisticToggle;
    this.resetToggles();
    this.statisticToggle = newValue;
  }

  toggleAnnouncement() {
    let newValue = !this.announcementToggle;
    this.resetToggles();
    this.announcementToggle = newValue;
    this.subMenuWacheClicked(WacheTabs.ANNOUNCEMENTS);
  }

  toggleLeaderboard() {
    let newValue = !this.leaderboardToggle;
    this.resetToggles();
    this.leaderboardToggle = newValue;
  }

  toggleAdmin() {
    let newValue = !this.adminToggle;
    this.resetToggles();
    this.adminToggle = newValue;
    this.subMenuAdminClicked();
  }

  changeLoginAsPerson(userId: string) {
    this.dataService.changeLoginAsPerson(userId, () => {
      this.$log.debug('Logged in as user: ' + this.account.usersWithAccess[userId]);
      this.resetToggles();
      this.loadLogo();
      setTimeout(() => {
        //Switch scope to home
        this.$state.go(this.getOverviewPath());
      }, 500)
    }, () => {
      this.$log.error('Could not login as user: ' + this.account.usersWithAccess[userId]);
    });
  }

  public goHome() {
    this.toggleHome();
    this.$state.go(this.getOverviewPath());
  }

  public setOverviewPath(state: string) {
    this.helperService.saveInStorage("overviewPath", state);
  }

  private getOverviewPath(): string {
    return this.helperService.getFromStorage("overviewPath", "main.home");
  }
}

interface WsState {
  isConnected: boolean;
}