'use strict';

import { EQueueEntryState, QueueEntryExpanded } from './../../../../data/queue.data';
import {IHttpService, ILogService, IRootScopeService, IScope, IWindowService} from "angular";
import {EMoveMode, MoveToQueueRequest, Queue, QueueResponse, UpdateQueueActiveStateRequest} from "../../../../data/queue.data";
import { StatusEntryResponse } from "../../../../data/vehicles.data";
import RestService from "../../../../services/rest.service";
import {RolePrivilege} from "../../../../data/privileges.enum";
import HelperService from '../../../../services/helper.service';
import PrivilegeService from '../../../../services/privilege.service';

require('./queue.container.scss');

export default class QueueContainerComponent {
  public restrict: string;
  public scope;
  public template;
  public controller;
  public controllerAs: string;
  public bindToController: boolean;
  constructor() {
    this.restrict = 'EA'
    this.scope = {
      queue: '='
    }
    this.template = require('./queue.container.html');

    this.controller = QueueContainerComponentController;
    this.controllerAs = 'ctrl';
    this.bindToController = true;
  }
}

class QueueContainerComponentController {
  private queue: Queue;
  private isLoading = false;
  private displayDoneElements = false;
  private queueResponse: QueueResponse;
  private listeners = [];
  private hasQueueEdit: boolean = false;
  public showFinished = false;

  private prio1Hidden = false;
  private prio2Hidden = false;
  private prio3Hidden = false;
  private prio4Hidden = false;


  constructor(public $scope: IScope, helperService: HelperService, public $rootScope: IRootScopeService, public restService: RestService, public $window: IWindowService,
    public $http: IHttpService, privilegeService: PrivilegeService, public $log: ILogService) {
      this.hasQueueEdit = privilegeService.has(RolePrivilege.Alarm_Queues_Edit);
    this.initListeners();
    this.showFinished = helperService.getFromStorage('QUEUE.DISPLAY_FINISHED', false);

  }

  load() {
    this.isLoading = true;

    let filter = '';
    if (this.prio1Hidden) {
      filter += '1;';
    }
    if (this.prio2Hidden) {
      filter += '2;';
    }
    if (this.prio3Hidden) {
      filter += '3;';
    }
    if (this.prio4Hidden) {
      filter += '4;';
    }

    // Load setting from local storage
    let toDisplay = localStorage.getItem(`display_done_elements_for_queue_${this.queue.id}`);
    if (toDisplay) {
      this.displayDoneElements = toDisplay === 'true';
    }

    this.restService.loadQueue(this.queue.id, filter)
      .then(queueResponse => {
        this.queueResponse = queueResponse;
        this.queue.empty = this.queueResponse.assignedCounter === 0 && this.queueResponse.inProgressCounter === 0;
      }).finally(() => {
        this.isLoading = false;
        this.$scope.$applyAsync();
        setTimeout(() => {
          this.queueResponse.assigned.forEach(entry => {
            let element = document.getElementById(entry.id);
            if (element) {
              element.addEventListener('dragstart', (ev: DragEvent) => {
                ev.dataTransfer.setData('queueEntryId', entry.id);
                ev.dataTransfer.setData('queueEntryState', entry.state);
              });
            }

          });
          this.queueResponse.inProgress.forEach(entry => {
            let element = document.getElementById(entry.id);
            if (element) {
              element.addEventListener('dragstart', (ev: DragEvent) => {
                ev.dataTransfer.setData('queueEntryId', entry.id);
                ev.dataTransfer.setData('queueEntryState', entry.state);
              });
            }

          });
        }, 250);
      });
  }

  initListeners() {

    this.listeners.push(this.$rootScope.$on('queue.show_finished', (event, value) => {
      this.showFinished = value;
    }));

    this.listeners.push(this.$scope.$watch('ctrl.queue', (oldValue, newValue) => {
      if (newValue) {
        this.load();
        this.listeners.push(this.$rootScope.$on(`queue.${this.queue.id}.update`, () => {
          this.load();
        }));

        this.listeners.push(this.$rootScope.$on(`queue.${this.queue.id}.update.state`, (event, queueResponse) => {
          this.queueResponse.active = queueResponse.active;
          this.load();
        }));

        if (this.queue.hasConnectedVehicle) {
          // Listen for status changes
          this.listeners.push(this.$rootScope.$on(`status.change.${this.queue.connectedVehicleId}`, (event, data: StatusEntryResponse) => {
            if (this.queueResponse) {
              this.queueResponse.status = data.status;
              this.queueResponse.statusColor = data.color;
              this.queueResponse.statusTextColor = data.textColor;
              this.$scope.$applyAsync();
            }
          }));
        }


        setTimeout(() => {
          // Allow drop
          let dropZone = document.getElementById(this.queue.id);
          let dropZoneInProgress = document.getElementById(`${this.queue.id}_progress`);

          if (dropZone) {
            // Allow drop and add drop highlight
            dropZone.addEventListener('dragover', (ev: DragEvent) => ev.preventDefault());
            dropZone.addEventListener('dragenter', (ev: DragEvent) => {
                dropZone.classList.add('queue-drop-highlight');
            });
            dropZone.addEventListener('dragleave', (ev: DragEvent) => {
              dropZone.classList.remove('queue-drop-highlight');
            });
            dropZone.addEventListener('drop', (ev: DragEvent) => {
              let entryId = ev.dataTransfer.getData('queueEntryId');
              let state = ev.dataTransfer.getData('queueEntryState') as EQueueEntryState;
              if (state === EQueueEntryState.ASSIGNED) {
                dropZone.classList.remove('queue-drop-highlight');
                this.restService.moveQueueEntryState(entryId, {
                  newQueueId: this.queue.id,
                  mode: EMoveMode.MOVE
                } as MoveToQueueRequest).catch(error => {
                  this.$log.warn(error.data?.message);
                });
              }
            });
          }
          if (dropZoneInProgress) {
            // Allow drop and add drop highlight
            dropZoneInProgress.addEventListener('dragover', (ev: DragEvent) => ev.preventDefault());
            dropZoneInProgress.addEventListener('dragenter', (ev: DragEvent) => {
                dropZoneInProgress.classList.add('queue-drop-highlight');
            });
            dropZoneInProgress.addEventListener('dragleave', (ev: DragEvent) => {
              dropZoneInProgress.classList.remove('queue-drop-highlight');
            });
            dropZoneInProgress.addEventListener('drop', (ev: DragEvent) => {
              let entryId = ev.dataTransfer.getData('queueEntryId');
              let state = ev.dataTransfer.getData('queueEntryState') as EQueueEntryState;
              if (state === EQueueEntryState.IN_PROGRESS) {
                dropZoneInProgress.classList.remove('queue-drop-highlight');
                this.restService.moveQueueEntryState(entryId, {
                  newQueueId: this.queue.id,
                  mode: EMoveMode.COPY
                } as MoveToQueueRequest).catch(error => {
                  this.$log.warn(error.data?.message);
                });
              }
            });
          }
        }, 250);
      }
    }));
    this.$scope.$on('$destroy', () => {
      this.listeners.forEach((listener) => {
        listener();
      });
    });
  }

  changeQueueActiveState(): void {
    if (!this.hasQueueEdit) {
      return;
    }
    this.restService.changeQueueState(this.queue.id, {
      active: !this.queueResponse.active
    } as UpdateQueueActiveStateRequest).then((response) => {
      this.queueResponse.active = response.active;
    }).finally(() => {
      this.$scope.$applyAsync();
    });
  }

  hidePrio1(): void {
    this.prio1Hidden = !this.prio1Hidden;
    this.load();
  }
  hidePrio2(): void {
    this.prio2Hidden = !this.prio2Hidden;
    this.load();
  }
  hidePrio3(): void {
    this.prio3Hidden = !this.prio3Hidden;
    this.load();
  }
  hidePrio4(): void {
    this.prio4Hidden = !this.prio4Hidden;
    this.load();
  }
  /**
   * Toggles either the done elements should be hidden or should be displayed. Persists the setting in local storage
   */
  toggleDisplayDoneElements(): void {
    this.displayDoneElements = !this.displayDoneElements;
    localStorage.setItem(`display_done_elements_for_queue_${this.queue.id}`, this.displayDoneElements + '');
  }

  /**
   * Export the queue as PDF
   * @param filterString A ; separated list of EQueueEntryState
   */
  exportPdf($event, filterString: string) {
    $event.stopPropagation();
    this.$window.open(
      this.restService.getBaseUrl() +
      `/queues/${this.queue.id}/export?states=${filterString}&Authorization=${this.$http.defaults.headers.common.Authorization}`, '_blank');
  }
}
