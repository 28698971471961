'use strict';

import {ILogService, IRootScopeService, IScope} from "angular";
import {UserAccount} from "../../../../data/account.data";
import {DocumentTag, EDocumentType, ObjectDocument} from "../../../../data/objects.data";
import HelperService from "../../../../services/helper.service";
import PrivilegeService from "../../../../services/privilege.service";
import RestService from "../../../../services/rest.service";

require('./edit.document.modal.css');

/* @ngInject */
export default class EditDocumentModalController {
  public $rootScope: any;
  public $uibModal: any;
  public $uibModalInstance: any;
  public $log: any;
  public dataService: any;
  public helperService: HelperService;
  public account: UserAccount;
  public isEditable = false;
  public selectedDocument: ObjectDocument;
  private documentsToUpload: ObjectDocument[] = [];
  public allTypes: EDocumentType[];
  public allFilteredTags: DocumentTag[];
  public allTags: DocumentTag[];
  public allGlobalTags: DocumentTag[];
  public selectedTags: DocumentTag[] = [];
  public isLoading: boolean;
  public uploadProgress = 0;
  public uploader: any;
  public localSearchFilter = "";
  public isNew = true;
  public isSer = false;

  constructor($rootScope: IRootScopeService, $uibModal, $uibModalInstance, $log: ILogService, dataService,
              helperService: HelperService, objectDocument: ObjectDocument,
              public privilegeService: PrivilegeService,
              private restService: RestService,
    private $scope: IScope,
  private okFunction: Function) {
    this.$rootScope = $rootScope;
    this.$uibModal = $uibModal;
    this.$uibModalInstance = $uibModalInstance;
    this.$log = $log;
    this.dataService = dataService;
    this.helperService = helperService;
    this.$log.debug('Opening EditDocumentModalController...');
    this.allTypes = [EDocumentType.PDF, EDocumentType.IMG];

    if (objectDocument) {
      this.isNew = false;
      this.isSer=objectDocument.ser;
      this.selectedDocument = objectDocument;
      this.selectedTags = this.selectedDocument.tagIds;
    }

    this.restService.getObjectDocumentTagsAll().then(response => {
      this.allTags = response.tags;
      this.allFilteredTags = this.allTags;
      this.allGlobalTags = response.globalTags;
    }).catch(error => {
      this.$log.error(error);
    });

    if (this.isNew) {
      this.uploader = this.restService.createDocumentObjectUploader();
    }
  }
  hasKohlhammerLicence() {
    var acc = this.dataService.getAccount();
    return acc!=null && acc.licenceDetails.nbrOfKohlhammer > 0;
  }

  filterTags() {

    this.allFilteredTags = this.allTags.filter(tag => tag.label.toLowerCase().includes(this.localSearchFilter.toLowerCase()));
    this.$scope.$applyAsync();
  }

  tagSelected(tag: DocumentTag): boolean {
    return this.selectedTags.filter(x => x.id === tag.id).length > 0;
  }

  addTag(tag: DocumentTag) {
    if (!this.tagSelected(tag)) {
      this.selectedTags.push(tag);
    }

    if (this.selectedDocument) {
      this.selectedDocument.tagIds = this.selectedTags;
    }
    this.$scope.$applyAsync();
  }

  validateDocument() {
    return this.isNew && this.uploader.queue.length > 0 ||
      !this.isNew && this.selectedDocument && this.selectedDocument.fileName && this.selectedDocument.fileName.length > 0;
  }

  deleteTag(tag: DocumentTag) {
    if (this.tagSelected(tag)) {
      const index = this.selectedTags.indexOf(tag);
      this.selectedTags.splice(index, 1);
    }
    if (this.selectedDocument) {
      this.selectedDocument.tagIds = this.selectedTags;
    }
    this.$scope.$applyAsync();
  }

  cancel() {
    this.$uibModalInstance.close();
  }

  uploadFiles() {
    if (this.uploader.queue.length === 0) {
      return;
    }
    this.isLoading = true;
    this.uploadProgress = 0;
    var stepSize = 100 / this.uploader.queue.length;
    this.uploadProgress += stepSize;
    this.$scope.$applyAsync();
    this.uploader.onBeforeUploadItem = (item) => {
      //Characters not affected by URL encode have to be removed
      let name = item.file.name.replace('&', '').replace('#', '');
      const tags = this.selectedTags.map(tag => tag.id).join(';');
      let type: EDocumentType;
      if (item.file.type === 'image/jpeg' || item.file.type === 'image/png') {
        type = EDocumentType.IMG;
      } else if (item.file.type === 'application/pdf') {
        type = EDocumentType.PDF;
      }

      //Change upload path
      item.url = item.url + '?name=' + encodeURI(name) + '&type=' + type + '&tagIds=' + tags + '&ser=' + this.isSer + '&overwrite=true';
    };


    //Success
    this.uploader.onSuccessItem = (item, response, status, headers) => {
      this.$log.info(`Upload completed: ${status}`);
      this.uploadProgress += stepSize;
      this.$scope.$applyAsync();

      //If file already exists, delete it
      var toDelete = -1;
      for (var i = 0; i < this.documentsToUpload.length; i++) {
        if (this.documentsToUpload[i].id === response.id) {
          toDelete = i;
          break;
        }
      }
      if (toDelete > -1) {
        this.documentsToUpload.splice(toDelete, 1);
      }

      this.documentsToUpload.push(response);
    };
    //Error
    this.uploader.onErrorItem = (item, response, status) => {
      this.$log.error(`Upload failed: ${status}`);
      this.$log.error(response);
      this.uploadProgress = 100;
      this.isLoading = false;
      this.$scope.$applyAsync();
    };


    this.uploader.onCompleteAll = () => {
      this.uploadProgress = 100;
      this.isLoading = false;
      this.$scope.$applyAsync();
      this.okFunction();
      this.cancel();
    };

    this.uploader.uploadAll();
  };


  save() {
    if (this.isNew) {
      this.uploadFiles();
    } else {
      this.updateDoc();
    }
  }

  updateDoc() {
    this.isLoading = true;
    this.selectedDocument.ser=this.isSer;
    this.restService.editDocumentObject(this.selectedDocument).then(response => {
      this.selectedDocument = response;
      this.isSer=this.selectedDocument.ser;
      this.isLoading = false;
      this.okFunction();
      this.cancel();
    }).catch(error => {
      this.$log.error(error);
      this.isLoading = false;
    });
  }
}
